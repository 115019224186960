import { ReactElement } from "react";
import styles from "./styles.module.scss";
import { TScrollToTop } from "@page-builder/ts/generated";

export default function ScrollToTop({
    props = {},
}: {
    props?: TScrollToTop["props"];
}): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <button onClick={() => scrollToTop()} className={styles.scrollToTop}>
            {props?.buttonText}
        </button>
    );
}
